<template>
    <div>
        <!-- 是否通过身份验证 -->
        <div class="center-member-works-verified"
            v-if="work_style.verified==1">
            <i class="el-icon-circle-check"></i>
            已校园认证
        </div>
        <div class="center-member-works-unverified"
            v-else>
            <i class="el-icon-warning-outline"></i>
            您还未通过校园认证，请先进行
            <router-link to="/center/campus">校园认证>></router-link>
        </div>
        <!-- 已通过风格 -->
        <ul class="center-member-works-verified-style">
            <li class="center-member-works-verified-style-title">
                已通过验证风格：
            </li>
            <li v-for="item in work_style.verifiedStyle"
                :key="item.id">
                {{item.name}}
            </li>
        </ul>
        <el-form
            :model="form"
            :rules="rules"
            label-position="top">
            <el-form-item
                label="选择风格 / Choose Your Style"
                prop="style">
                <ul class="center-member-works-style">
                    <li>
                        <el-checkbox v-model="form.style" label="1">户外写真</el-checkbox>
                        <span>包括 活动跟拍（校园主题） / 日系清新风（校园主题） / 环境人像（校园主题） / 环境人像写真（个人及家庭主题） / JK制服（个人与家庭主题）</span>
                    </li>
                    <li>
                        <el-checkbox v-model="form.style" label="2">二次元</el-checkbox>
                        <span>包括 二次元（校园主题） / 二次元（个人及家庭主题）</span>
                    </li>
                    <li>
                        <el-checkbox v-model="form.style" label="3">影棚写真-单灯艺术肖像</el-checkbox>
                        <span>包括 影棚艺术肖像（校园主题） / 最美证件照（校园主题） / 儿童肖像（个人与家庭主题） / 家庭影像（个人与家庭主题）</span>
                    </li>
                    <li>
                        <el-checkbox v-model="form.style" label="4">影棚写真-多灯肖像</el-checkbox>
                        <span>包括 个人肖像（个人与家庭主题） / 形象摄影（商业影像）</span>
                    </li>
                    <li>
                        <el-checkbox v-model="form.style" label="5">商业活动跟拍</el-checkbox>
                        <span>包括 活动跟拍（商业影像）</span>
                    </li>
                    <li>
                        <el-checkbox v-model="form.style" label="6">产品摄影</el-checkbox>
                        <span>包括 产品摄影（商业影像）</span>
                    </li>
                </ul>
            </el-form-item>
            <!-- <el-form-item
                label="所在地区 / Location"
                prop="service">
                <area-cascader 
                    v-model="form.service"
                    type='text'
                    :level='1'
                    :data="$pcaa"
                    placeholder="请选择地址"
                    class="center-member-works-service">
                </area-cascader>
            </el-form-item>
            <el-form-item
                label="服务范围 / Service Area"
                prop="address">
                <el-input
                    v-model="form.address"
                    class="center-member-works-map-search"
                    @keyup.enter.native="handleSearch">
                    <el-button 
                        slot="append" 
                        icon="el-icon-search"
                        @click="handleSearch">
                    </el-button>
                </el-input>
                <div class="center-member-works-map">
                    <b-map
                        :map-height="300"
                        :map-position="position"
                        :map-change="setInfoChange"
                        ref="mapSearch">
                    </b-map>
                </div>
            </el-form-item>
            <el-form-item
                label="简介 / Brief Introduction"
                prop="brief">
                <el-input
                    v-model="form.brief"
                    type="textarea">
                </el-input>
            </el-form-item> -->
            <el-form-item>
                <el-button
                    type="warning"
                    @click="handleSubmit({
                        styleIDs: form.style,
                        serviceArea: form.service,
                        briefInfo: form.brief,
                        address: form.address,
                        position
                    })"
                    class="center-member-works-btn">
                    保存
                </el-button>
                <el-button
                    type="warning"
                    @click="handleOpenDialog"
                    class="center-member-works-btn">
                    上传作品
                </el-button>
            </el-form-item>
        </el-form>
        <center-member-dialog
            :checked-style="form.style">
        </center-member-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
    data() {
        return {
            form: {
                style: [],
                // service: "",
                // address: "",
                // brief: ""
            },
            position: {
                lat: "", // 纬度
                lng: "", // 经度
            },
            rules: {
                style: [
                    { required: true, message: '请选择风格', trigger: 'blur' }
                ],
                // service:[
                //     { required: true, message: '请选择所在地区', trigger: 'blur' }
                // ],
                // address:[
                //     { required: true, message: '请选择服务范围', trigger: 'blur' }
                // ],
                // brief: [
                //     { required: true, message: '请填写简介', trigger: 'blur' }
                // ],
            },
        }
    },
    props: {
        "handleSubmit": Function,
        "handleGetworks": Function
    },
    computed: mapState({
        user_id: state => state.user.user_id,
        work_style: state => state.user.work_style,
    }),
    components: {
        centerMemberDialog: () => import("@/components/centerMemberDialog"),
        bMap: () => import("@/components/bMap")
    },
    methods: {
        ...mapMutations([
            "changeDialogVisible",
            "setInfoChange"
        ]),
        ...mapActions([]),
        setForm(val){ // 写入参数
            let new_val = [];
            if(val.styleIDs !== undefined){
                for (let item of val.styleIDs) {
                    new_val.push(item.id)
                }
            }
            this.form = {
                style: new_val,
                service: val.serviceArea || "",
                brief: val.briefInfo || "",
                address: val.address || this.form.address
            }
            // if(val.position && JSON.stringify(val.position) !== "{}"){
            //     this.position = {
            //         lat: val.position.lat,
            //         lng: val.position.long,
            //     } 
            // }
        },
        // handleSearch(){ // 搜索功能
        //     let val = this.form.service.join("");
        //     if(this.form.address){
        //         val = val.concat(this.form.address)
        //     }
        //     this.$refs.mapSearch.handleSearch(val)
        // },
        handleOpenDialog(){ // 上传作品按钮
            this.changeDialogVisible(true);
            this.handleGetworks();
        }
    },
    watch: {
        work_style: {
            handler: "setForm",
            immediate: true,
            deep: true
        }
    },
}
</script>

<style scoped>
/* 实名认证 */
.center-member-works-verified{
    margin-bottom: 10px;
    color: #67C23A;
}
.center-member-works-unverified{
    margin-bottom: 10px;
    font-weight: bold;
    color: #E6A23C;
}
/* 已通过验证风格 */
.center-member-works-verified-style{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
.center-member-works-verified-style > li{
    flex-shrink: 1;
}
.center-member-works-verified-style > .center-member-works-verified-style-title{
    flex-shrink: 0;
    color: #E6A23C;
    font-weight: bold;
}
.center-member-works-verified-style>li+li{
    margin-left: 10px;
    color: #909399;
}
/* 风格小标题 */
.center-member-works-style-title{
    color: #909399;
}
/* 风格项 */
.center-member-works-style>li{
    padding-bottom: 10px;
    border-bottom: 1px solid #EBEEF5;
}
/* 说明文字 */
.center-member-works-style>li>span{
    display: block;
    color: #909399;
}
/* 风格多选框 */
.center-member-works-style>>>.el-checkbox-group{
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px;
}
/* 服务范围 */
.center-member-works-service{
    display: inline-block;
}
.center-member-works-service>>>.area-select{
    /* width: 100%; */
    height: 40px;
}
.center-member-works-service>>>.area-selected-trigger{
    padding: 0 20px 0 12px;
}
.center-member-works-map-search{
    width: 300px;
}
.center-member-works-map{
    margin-top: 10px;
}
/* 按钮 */
</style>